import { createApi } from "@reduxjs/toolkit/query/react";
import { ExceptionsConstants } from "@src/shared/enums/exceptions";
import { FilterUserSearch } from "@src/shared/interfaces/FilterUserSearch";
import { Pagination } from "@src/shared/interfaces/pagination";
import { AppDispatch } from "@src/store";
import { User } from "@src/store/slices/login/types";
import { userActions } from "@src/store/slices/users";
import { formatPhone } from "@src/utils/phone";
import { sucessfulResponseValidation } from "@src/utils/sucessfulResponseValidation";
import { toast } from "@src/utils/toast";
import { format, isValid } from "date-fns";
import jwtDecode from "jwt-decode";

import { api, baseQueryMiddleware, hasMoreItems, mergePaginationItems } from "./api";
import { infoClient, ipClient } from "./externalApis";

type UserData = {
  name?: string;
  phone?: string;
  document?: string;
  email?: string;
  avatar?: string;
  isActive?: boolean;
  department?: string;
  customer?: string;
  roles?: string[];
};

type PasswordData = {
  password: string;
  token: string;
};

export type TermsData = {
  ip?: string;
  so?: string;
  lat?: number;
  lon?: number;
  emailMkt?: boolean;
  user: string;
  city?: string;
  state?: string;
  newCpf?: string;
  newPhone?: string;
};

const handleDomainNotWhitelistedError = (dispatch: AppDispatch, email: string): boolean => {
  const domain = email.split("@")[1];
  toast({
    type: "error",
    title: "Domínio de E-mail Não Permitido",
    message: `O domínio deste e-mail ${domain} não é aceito para cadastrar usuário administrador. Use um e-mail com um domínio permitido.`,
  });
  dispatch(userActions.controlView({ isLoading: false, hasError: false }));
  return false;
};

const handleExistingDocumentError = (dispatch: AppDispatch): void => {
  toast({
    type: "error",
    title: "CPF já registrado",
    message:
      "Tente novamente com um CPF diferente ou verifique as informações do usuário existente.",
  });
  dispatch(userActions.controlView({ isLoading: false, hasError: false }));
  return;
};

export const createUser = (user: UserData) => async (dispatch: AppDispatch) => {
  const { customer, department, roles = ["USER"], ...newUser } = user;
  const data = { ...newUser, departmentIds: [department], isActive: true, roles };
  try {
    if (customer) {
      dispatch(userActions.controlView({ isLoading: true, hasError: false }));
      const { status } = await api.post(`/customers/${customer}/users`, data);
      if (status == 200) {
        dispatch(userActions.controlView({ isLoading: false, hasError: false, msgError: "" }));
        return true;
      }
    }
  } catch (err: any) {
    if (err?.response?.data?.message === ExceptionsConstants.DOMAIN_NOT_WHITELISTED) {
      return handleDomainNotWhitelistedError(dispatch, user.email);
    }

    dispatch(
      userActions.controlView({
        isLoading: false,
        hasError: true,
        msgError: err.response.data.message,
      }),
    );
  }
};

export const deleteUser = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const { status } = await api.delete(`/users/${userId}`);
    if (status == 204) {
      dispatch(userActions.controlView({ isLoading: false, hasError: false }));
      return true;
    } else {
      throw new Error("Failed to delete user");
    }
  } catch (err: any) {
    dispatch(
      userActions.controlView({
        isLoading: false,
        hasError: true,
        msgError: err.response?.data?.message || "Erro ao excluir o usuário.",
      }),
    );
    return false;
  }
};

export const updateUser = (user: UserData, userId: string) => async (dispatch: AppDispatch) => {
  const { customer, department, roles = ["USER"], ...newUser } = user;
  const data = department
    ? { ...newUser, departmentIds: [department], roles }
    : { ...newUser, roles };
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false, msgError: "" }));
    const { status } = await api.put(`/users/${userId}`, data);
    if (status == 200) {
      dispatch(userActions.controlView({ isLoading: false, hasError: false, msgError: "" }));
      return true;
    }
  } catch (err: any) {
    if (err?.response?.data?.message === ExceptionsConstants.DOMAIN_NOT_WHITELISTED) {
      return handleDomainNotWhitelistedError(dispatch, user.email);
    }

    if (err?.response?.data?.message === ExceptionsConstants.EXISTING_DOCUMENT) {
      return handleExistingDocumentError(dispatch);
    }

    if (
      err?.response?.data?.message === ExceptionsConstants.INACTIVE_CUSTOMER &&
      newUser.isActive
    ) {
      dispatch(
        userActions.controlView({
          isLoading: false,
          hasError: true,
          msgError: err.response.data.message,
        }),
      );
      toast({
        title: "Ocorreu um erro",
        message:
          "Não é possível desbloquear este usuário enquanto o cliente estiver inativo. Por favor, ative o cliente primeiro.",
        type: "error",
      });
    } else {
      dispatch(
        userActions.controlView({
          isLoading: false,
          hasError: true,
          msgErr: err.response.data.message,
        }),
      );
      toast({
        title: "Ocorreu um erro",
        message: `Erro ao ${user.isActive ? "desbloquear" : "bloquear"} o usuário.`,
        type: "error",
      });
    }
  }
};

export const defineFirstPassword = (data: PasswordData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const { status } = await api.post("/auth/define-password", data);
    if (sucessfulResponseValidation(status)) {
      dispatch(userActions.controlView({ isLoading: false, hasError: false }));
      return true;
    }
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: true }));
  }
};

export const validateToken = (token: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await api.post("/auth/validate-token", { token });
    const user = jwtDecode<User>(token);
    dispatch(
      userActions.controlData({
        user: {
          id: user.id,
          name: user.name,
          email: user.email,
          roles: user.roles,
          privacy: user.privacy,
          tenant: user.tenant,
          customer: user.customer,
          customerName: user.customerName,
          departmentId: user.departmentId,
          departmentName: user.departmentName,
          phone: user.phone,
        },
      }),
    );
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      valid: response?.data.valid,
      privacy: {
        assign: response?.data?.privacy?.assign,
      },
      user: {
        id: user.id,
        cpf: response?.data?.user?.cpf,
        phone: response?.data?.user?.phone,
      },
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};

export const termsAccept = (terms: TermsData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await api.post("/privacy", { ...terms });
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      privacy: {
        assign: response?.data?.privacy?.assign,
      },
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};
export const findClientInfos = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await infoClient.request({ method: "get" });

    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      city: response?.data?.city,
      state: response?.data?.region_code,
      lat: response?.data?.longitude,
      lon: response?.data?.latitude,
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};

export const findIp = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await ipClient.request({ method: "get" });

    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      ip: response?.data?.ip,
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: true }));
    return {
      ip: null,
    };
  }
};
export const changePassword =
  (password: string, userId: string) => async (dispatch: AppDispatch) => {
    const data = { password };
    try {
      if (password) {
        dispatch(userActions.controlView({ isLoading: true, hasError: false }));
        const { status } = await api.put(`/auth/change-password/${userId}`, data);
        if (sucessfulResponseValidation(status)) {
          dispatch(userActions.controlView({ isLoading: false, hasError: false }));
          return true;
        }
      }
    } catch (err: any) {
      dispatch(
        userActions.controlView({
          isLoading: false,
          hasError: true,
          msgError: err.response.data.message,
        }),
      );
    }
  };

export const usersApi = createApi({
  reducerPath: "usersApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryMiddleware,
  endpoints: builder => ({
    getUsers: builder.query({
      query: (params: Pagination) => ({
        url: `/users`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        if (baseQueryReturnValue && baseQueryReturnValue.rows) {
          return {
            ...baseQueryReturnValue,
            hasMore: hasMoreItems(baseQueryReturnValue),
            rows: baseQueryReturnValue?.rows?.map((user: any) => {
              const lastLoginDate = user?.lastLogin
                ? isValid(new Date(user.lastLogin))
                  ? format(new Date(user.lastLogin), "dd/MM/yyyy")
                  : null
                : null;
              const createdAtDate = user?.createdAt
                ? isValid(new Date(user.createdAt))
                  ? format(new Date(user.createdAt), "dd/MM/yyyy")
                  : null
                : null;

              return {
                ...user,
                lastLogin: lastLoginDate,
                createdAt: createdAtDate,
                phone: formatPhone(user?.phone),
              };
            }),
          };
        }

        return baseQueryReturnValue;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: mergePaginationItems,
    }),
    getUser: builder.query({
      query: ({ user, customer }) => ({
        url: `/customers/${customer}/users/${user}`,
        method: "GET",
      }),
    }),
    getUsersSearch: builder.query({
      query: ({ params, body }: { params?: Pagination; body: FilterUserSearch }) => ({
        url: `/users/search`,
        method: "POST",
        body,
        params,
      }),
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        if (baseQueryReturnValue && baseQueryReturnValue.rows) {
          return {
            ...baseQueryReturnValue,
            hasMore: hasMoreItems(baseQueryReturnValue),
            rows: baseQueryReturnValue?.rows?.map((user: any) => {
              const lastLoginDate = user?.lastLogin
                ? isValid(new Date(user.lastLogin)) &&
                  format(new Date(user.lastLogin), "dd/MM/yyyy")
                : null;
              const createdAtDate = user?.createdAt
                ? isValid(new Date(user.createdAt)) &&
                  format(new Date(user.createdAt), "dd/MM/yyyy")
                : null;

              return {
                ...user,
                lastLogin: lastLoginDate,
                createdAt: createdAtDate,
                phone: formatPhone(user?.phone),
              };
            }),
          };
        }
        return baseQueryReturnValue;
      },
    }),
    updateUser: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetUserQuery,
  useLazyGetUsersSearchQuery,
} = usersApi;
