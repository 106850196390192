import { createApi } from "@reduxjs/toolkit/query/react";
import { SolutionsData } from "@src/components/customers/Interfaces";
import { AppDispatch } from "@src/store";
import { solutionsActions } from "@src/store/slices/solutions";
import { sucessfulResponseValidation } from "@src/utils/sucessfulResponseValidation";

import { api, baseQueryMiddleware } from "./api";

export const solutionsApi = createApi({
  reducerPath: "solutionsApi",
  baseQuery: baseQueryMiddleware,
  endpoints: builder => ({
    getSolutionsByCustomer: builder.query({
      query: (customerId: string) => ({
        url: `/solutions/customer/${customerId}`,
        method: "GET",
      }),
    }),
  }),
});

export const postSolutions = (solutionsData: SolutionsData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(solutionsActions.controlView({ isLoading: true, hasError: false }));
    const { status } = await api.post("/solutions", solutionsData);
    if (status == 200) {
      dispatch(solutionsActions.controlView({ isLoading: false, hasError: false }));
      return { success: true, statusCode: status };
    }
  } catch (err: any) {
    dispatch(solutionsActions.controlView({ isLoading: false, hasError: true }));

    const statusCode = err?.response?.status || 500;
    const message = err?.response?.data?.message || err.message || "Unknown error";
    return { error: true, statusCode, message };
  }
};

export const deleteSolution =
  (id: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(solutionsActions.controlView({ isLoading: true, hasError: false }));

      const { status } = await api.delete(`/solutions/${id}`);
      if (!sucessfulResponseValidation(status)) throw new Error("Erro ao excluir solução");
      dispatch(solutionsActions.controlView({ isLoading: false, hasError: false }));
    } catch (err: any) {
      dispatch(solutionsActions.controlView({ isLoading: false, hasError: true }));
      throw new Error(err.message);
    }
  };

export const { useLazyGetSolutionsByCustomerQuery } = solutionsApi;
