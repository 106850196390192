import { createSlice } from "@reduxjs/toolkit";
import { generateReducersControllers } from "@src/store/utils";

import { ISolutionsState, IData, IView } from "./types";

export const initialState: ISolutionsState = {
  data: {},
  view: {},
};

export const slice = createSlice({
  name: "solutions",
  initialState,
  reducers: {
    ...generateReducersControllers<IView, IData>(),
  },
});

export const { actions: solutionsActions } = slice;
export default slice.reducer;
