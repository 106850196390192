export enum ExceptionsConstants {
  INACTIVE_CUSTOMER = "exception:INACTIVE_CUSTOMER",
  DOMAIN_NOT_WHITELISTED = "exception:DOMAIN_NOT_WHITELISTED",
  FAILED_DELETE_REPORT_FILTERS = "exception:FAILED_DELETE_REPORT_FILTERS",
  FAILED_DELETE_DEPARTMENTS = "exception:FAILED_DELETE_DEPARTMENTS",
  FAILED_DELETE_USERS = "exception:FAILED_DELETE_USERS",
  FAILED_DELETE_CUSTOMER = "exception:FAILED_DELETE_CUSTOMER",
  INACTIVE_USER = "exception:INACTIVE_USER",
  EXISTING_DOCUMENT = "CPF já cadastrado.",
}
